<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="90px"
        :model="quantity"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('quantity.name')" prop="name">
            <el-input v-model="quantity.name" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('quantity.abbreviation')" prop="abbreviation">
            <el-input v-model="quantity.abbreviation" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('quantity.districtId')" prop="districtId">
            <vm-district-select v-model="quantity.districtId" :change-on-select="true"></vm-district-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('quantity.address')" prop="address">
            <el-input v-model="quantity.address" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmDistrictSelect from "../../components/VmDistrictSelect";

  const moduleName = "quantities";

  export default {
    components: {VmDistrictSelect},
    data() {
      return {
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        quantity: {
          id: 0,
          no: "",
          name: "",
          abbreviation: "",
          districtId: 0,
          address: "",
        },
        ruleValidate: {
          no: [
            {required: true, message: this.$t("quantity.tip.no"), trigger: "blur"},
          ],
          name: [
            {required: true, message: this.$t("quantity.tip.name"), trigger: "blur"},
          ],
          districtId: [
            {type: "number", required: true, min: 1, message: this.$t("quantity.tip.districtId"), trigger: "change"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.quantity.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.qualitySupervision");
      },
    },
    mounted() {
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.quantity.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.quantity.id).then(res => {
          this.quantity = Object.assign(this.quantity, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.quantity).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.quantity.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
